import { StepDefinition, IStepDefinitionOptions, IOfflineStepDefinition, IOnlineStepDefinition, IStepTruncateIndexedDB, type ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { DataObjectProgress, type IDataObjectProgressOptions, type IDataObjectProgressJSON } from 'o365.pwa.modules.client.steps.DataObjectProgress.ts';
import { getDataObjectById, type DataObject } from 'o365-dataobject';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { app, userSession } from 'o365-modules';
import { type SyncType, type TruncateIndexDBObjectStoreMode } from "o365.pwa.types.ts";
import type { IOfflineSyncProgress, IOnlineSyncProgress, ITruncateProgress } from 'o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts';
import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import 'o365.dataObject.extension.Offline.ts';

export type FileTableType = 'BLOB' | 'BINARY';

interface IOnBeforeSyncResponse {
    error: Error,
    title: string,
    body: string;
}

interface IOnAfterSyncResponse {
    error: Error,
    title: string,
    body: string;
}

type OnBeforeSync = (dataObject: DataObject, meomory: Object, requestOptions: any) => Promise<IOnBeforeSyncResponse | void>;
type OnAfterSync = (dataObject: DataObject, meomory: Object, records: Array<any>) => Promise<IOnAfterSyncResponse | void>;

export interface IDataObjectStepDefinitionOptions extends IStepDefinitionOptions {
    dataObjectId: string;
    truncateIndexDBObjectStoreMode?: TruncateIndexDBObjectStoreMode;
    onBeforeSync?: OnBeforeSync;
    onAfterSync?: OnAfterSync;
    useOfflineTable?: boolean;
    fileTableType?: FileTableType;
    maxRecords?: number;
    rowCountTimeout?: number;
    failOnNoRecords?: boolean;
    loadPropertyConfigs?: boolean;
    propertyConfigViewName?: string;
}

export class DataObjectStepDefinition extends StepDefinition implements IOfflineStepDefinition<DataObjectProgress>, IOnlineStepDefinition<DataObjectProgress>, IStepTruncateIndexedDB<DataObjectProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';
    public readonly IStepTruncateIndexedDB = 'IStepTruncateIndexedDB';

    public readonly dataObjectId: string;
    public readonly onBeforeSync?: OnBeforeSync;
    public readonly onAfterSync?: OnAfterSync;
    public readonly truncateMode?: TruncateIndexDBObjectStoreMode;
    public readonly fileTableType?: FileTableType;
    public readonly rowCountTimeout?: number;
    public readonly failOnNoRecords?: boolean;
    public readonly loadPropertyConfigs?: boolean;
    public readonly propertyConfigViewName?: string;
    public readonly maxRecords?: number;
    public readonly useOfflineTable?: boolean;


    private get isFileTable(): Boolean {
        return this.fileTableType !== undefined && this.fileTableType !== null;
    }

    private get generateOfflineDataProcName(): string {
        return this.isFileTable ? 'sstp_System_GenerateOfflineDataFiles' : 'sstp_System_GenerateOfflineData';
    }

    private get mySystemOfflineDataViewName(): string {
        return this.isFileTable ? 'sviw_System_MyOfflineDataFiles' : 'sviw_System_MyOfflineData';
    }

    private get mySystemOfflineDataFields(): Array<{ name: string }> {
        let fields = [
            { name: 'PrimKey' },
            { name: 'Created' },
            { name: 'CreatedBy_ID' },
            { name: 'Updated' },
            { name: 'UpdatedBy_ID' },
            { name: 'JsonData' },
            { name: 'Type' },
            { name: 'Owner_ID' },
            { name: 'LastCheckIn' },
            { name: 'AppID' },
            { name: 'JsonDataVersion' },
            { name: 'ExternalRef' }
        ];

        if (this.isFileTable) {
            fields = fields.concat([
                { name: 'FileName' },
                { name: 'FileSize' },
                { name: 'FileUpdated' },
                { name: 'FileRef' },
                { name: 'Extension' }
            ]);
        }

        return fields;
    }

    constructor(options: IDataObjectStepDefinitionOptions) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'DataObjectProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.DataObjectProgress.vue');
            }
        });

        this.dataObjectId = options.dataObjectId;
        this.truncateMode = options.truncateIndexDBObjectStoreMode;
        this.onBeforeSync = options.onBeforeSync;
        this.onAfterSync = options.onAfterSync;
        this.fileTableType = options.fileTableType;
        this.rowCountTimeout = options.rowCountTimeout;
        this.failOnNoRecords = options.failOnNoRecords;
        this.loadPropertyConfigs = options.loadPropertyConfigs;
        this.propertyConfigViewName = options.propertyConfigViewName;
        this.maxRecords = options.maxRecords;
        this.useOfflineTable = options.useOfflineTable;
    }

    public toRunStepDefinition(): DataObjectStepDefinition {
        return new DataObjectStepDefinition({
            dataObjectId: this.dataObjectId,
            stepId: this.stepId,
            title: this.title,
            dependOnPreviousStep: this.dependOnPreviousStep,
            failOnNoRecords: this.failOnNoRecords,
            fileTableType: this.fileTableType,
            loadPropertyConfigs: this.loadPropertyConfigs,
            propertyConfigViewName: this.propertyConfigViewName,
            onAfterSync: this.onAfterSync,
            onBeforeSync: this.onBeforeSync,
            rowCountTimeout: this.rowCountTimeout,
            maxRecords: this.maxRecords,
            truncateIndexDBObjectStoreMode: this.truncateMode,
            useOfflineTable: this.useOfflineTable
        })
    }

    generateStepProgress(options?: IDataObjectProgressOptions | IDataObjectProgressJSON, syncType?: SyncType): DataObjectProgress {
        const progressOptions = <IDataObjectProgressOptions>{
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback,
        }

        return new DataObjectProgress(progressOptions);
    }

    async syncOffline(options: ISyncOptions<DataObjectProgress>): Promise<void> {
        try {
            // const userSession = getUserSession();

            // if (userSession?.personId !== 64800) {
            //     return;
            // }

            // TODO: Switch onBeforeSyncResponse and onAfterSyncResponse to interface or class to contain both online and offline settings
            // TODO: Switch paramaters to interface or class to contain both online and offline settings

            const dataObject: DataObject = getDataObjectById(this.dataObjectId, app.id);

            if (dataObject === undefined) {
                debugger;
            }

            dataObject.enableOffline();

            const appId = app.id;

            const requestGuid = self.crypto.randomUUID();
            const device = await IndexedDbHandler.getUserDevice();

            const dataObjectOptions = dataObject.recordSource.getOptions();

            delete dataObjectOptions.filterString;
            delete dataObjectOptions.whereClause;
            delete dataObjectOptions.masterDetailString;
            delete dataObjectOptions.filterObject;
            delete dataObjectOptions.whereObject;
            delete dataObjectOptions.indexedDbWhereExpression;
            delete dataObjectOptions.masterDetailObject;

            const requestOptions: any = Object.assign({}, {
                requestGuid: requestGuid,

                appId: appId,
                dataObjectId: dataObject.id,

                appIdOverride: dataObject.offline.appIdOverride,
                databaseIdOverride: dataObject.offline.databaseIdOverride,
                objectStoreIdOverride: dataObject.offline.objectStoreIdOverride,

                personID: userSession?.personId,
                deviceRef: device?.deviceRef,

                isFileTable: this.isFileTable,

                truncateOptions: {
                    truncateMode: this.truncateMode,
                },

                generateOfflineDataOptions: {
                    shouldGenerateOfflineData: dataObject.offline.shouldGenerateOfflineData,
                },

                rowCountOptions: {
                    timeout: this.rowCountTimeout,
                    failOnNoRecords: this.failOnNoRecords,
                    dataObjectOptions: dataObjectOptions,
                },

                retrieveOptions: {
                    dataObjectOptions: dataObjectOptions
                },

                loadPropertyConfigs: this.loadPropertyConfigs ?? false
            });

            if (dataObject.offline.shouldGenerateOfflineData) {
                requestOptions.generateOfflineDataOptions.originalViewName = dataObject.viewName;
                requestOptions.generateOfflineDataOptions.viewName = this.mySystemOfflineDataViewName;
                requestOptions.generateOfflineDataOptions.fields = this.mySystemOfflineDataFields;

                requestOptions.generateOfflineDataOptions.generateOfflineDataProcName = this.generateOfflineDataProcName;
                requestOptions.generateOfflineDataOptions.generateOfflineDataViewNameOverride = dataObject.offline.generateOfflineDataViewNameOverride;
                requestOptions.generateOfflineDataOptions.generateOfflineDataProcedureNameOverride = dataObject.offline.generateOfflineDataProcedureNameOverride;
            }

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(dataObject, options.memory, requestOptions);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));

                    return;
                }
            }

            const onMessage = (event: MessageEvent | { data: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress }) => {
                const message: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress = event.data;

                if (message.requestGuid !== requestGuid) {
                    return;
                }

                switch (message.syncType) {
                    case 'OFFLINE-SYNC':
                        options.stepProgress.offlineSyncInProgress = message;
                        break;
                    case 'ONLINE-SYNC':
                        options.stepProgress.onlineSyncInProgress = message;
                        break;
                    case 'TRUNCATE':
                        options.stepProgress.truncateInProgress = message;
                        break;
                }
            };

            navigator.serviceWorker.addEventListener('message', onMessage);

            const response = await fetch(`/nt/api/pwa/offline-sync/${dataObject.id}`, {
                method: 'POST',
                body: JSON.stringify(requestOptions),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            navigator.serviceWorker.removeEventListener('message', onMessage);

            const responseBodyJson = await response.json();

            const progress: IOfflineSyncProgress = responseBodyJson.progress;

            options.stepProgress.offlineSyncCompletedProgress = progress;

            if (progress.generateOfflineDataCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.generateOfflineDataError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'GENERATE-OFFLINE-DATA-OBJECT-STORE-NOT-FOUND':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                        case 'GENERATE-OFFLINE-DATA-OBJECT-STORE-VERSION-MISMATCH':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                        case 'GENERATE-OFFLINE-DATA-RESPONSE-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                        case 'GENERATE-OFFLINE-DATA-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                } else {
                    options.stepProgress.errors.push(new Error('Failed to generate records'));
                }

                return;
            }

            if (progress.retrieveRowCountCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.retrieveRowCountCompletedError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'RETRIEVE-ROW-COUNT-NO-RECORDS-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                        case 'RETRIEVE-ROW-COUNT-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                } else {
                    options.stepProgress.errors.push(new Error('Failed to retrieve row count'));
                }

                return;
            }

            if (progress.retrieveRecordsCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.retrieveRecordsCompletedError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'RETRIEVE-RECORDS-RESPONSE-PARSE-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                        case 'RETRIEVE-RECORDS-RESPONSE-READER-MISSING-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                        case 'RETRIEVE-RECORDS-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                } else {
                    options.stepProgress.errors.push(new Error('Failed to retrieve records'));
                }

                return;
            }

            if (progress.retrieveFilesCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.retrieveFilesCompletedError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'RETRIEVE-FILES-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                } else {
                    options.stepProgress.errors.push(new Error('Failed to retrieve files'));
                }

                return;
            }


            if (
                this.loadPropertyConfigs &&
                this.propertyConfigViewName &&
                options.stepProgress.offlineSyncCompletedProgress.propertyConfigs
            ) {
                options.syncProgress.customData.propertyConfigs ??= new Set<string>();

                const array = options.stepProgress.offlineSyncCompletedProgress.propertyConfigs;

                array.forEach((config) => options.syncProgress.customData.propertyConfigs.add(config));

                options.syncProgress.customData.dataObjectPropertyViewNames ??= new Set<string>();
                options.syncProgress.customData.dataObjectPropertyViewNames.add(this.propertyConfigViewName);

            }

            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(dataObject, options.memory, []);

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));

                    return;
                }
            }
        } catch (error: any) {
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
            options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', 'Try again or contact support if the issue does not get resolved'));
        }
    }

    async syncOnline(options: ISyncOptions<DataObjectProgress>): Promise<void> {
        try {
            // TODO: Switch onBeforeSyncResponse and onAfterSyncResponse to interface or class to contain both online and offline settings
            // TODO: Switch paramaters to interface or class to contain both online and offline settings

            const dataObject: DataObject = getDataObjectById(this.dataObjectId, app.id);

            dataObject.enableOffline();

            if (dataObject.shouldEnableOffline === false) {
                throw Error('Invalid DataObject. DataObject must be flagged to use generated offline data to run online sync')
            }

            const appId = app.id;

            const requestGuid = self.crypto.randomUUID();
            const device = await IndexedDbHandler.getUserDevice();

            const requestOptions = {
                requestGuid: requestGuid,
                appId: appId,
                dataObjectId: dataObject.id,
                originalViewName: dataObject.viewName,
                viewName: this.mySystemOfflineDataViewName,
                offlineDataType: dataObject.offline.objectStoreIdOverride ?? dataObject.id,
                objectStoreIdOverride: dataObject.offline.objectStoreIdOverride,
                personID: userSession.personId,
                truncateMode: this.truncateMode,
                deviceRef: device?.deviceRef

            };

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(dataObject, options.memory, requestOptions);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));
                }
            }

            const onMessage = (event: MessageEvent | { data: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress }) => {
                const message: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress = event.data;

                if (message.requestGuid !== requestGuid) {
                    return;
                }

                switch (message.syncType) {
                    case 'OFFLINE-SYNC':
                        options.stepProgress.offlineSyncInProgress = message;
                        break;
                    case 'ONLINE-SYNC':
                        options.stepProgress.onlineSyncInProgress = message;
                        break;
                    case 'TRUNCATE':
                        options.stepProgress.truncateInProgress = message;
                        break;
                }
            };

            navigator.serviceWorker.addEventListener('message', onMessage);

            const response = await fetch(`/nt/api/pwa/online-sync/${dataObject.id}`, {
                method: 'POST',
                body: JSON.stringify(requestOptions),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            navigator.serviceWorker.removeEventListener('message', onMessage);

            const responseBodyJson = await response.json();

            const progress: IOnlineSyncProgress = responseBodyJson.progress;

            options.stepProgress.onlineSyncCompletedProgress = progress;

            if (progress.retrieveRowCountCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.retrieveRowCountCompletedError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'RETRIEVE-ROW-COUNT-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                } else {
                    options.stepProgress.errors.push(new Error('Failed to retrieve row count'));
                }

                return;
            }

            if (progress.uploadRecordsCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.uploadRecordsCompletedError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'UPLOAD-RECORDS-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                } else {
                    options.stepProgress.errors.push(new Error('Failed to upload records'));
                }

                return;
            }

            if (progress.uploadFilesCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.uploadFilesCompletedError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'UPLOAD-FILES-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                } else {
                    options.stepProgress.errors.push(new Error('Failed to upload files'));
                }

                return;
            }

            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(dataObject, options.memory, new Array());

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));
                }
            }
        } catch (error: any) {
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
        }
    }

    async truncateData(options: ISyncOptions<DataObjectProgress>): Promise<void> {
        try {
            const dataObject: DataObject = getDataObjectById(this.dataObjectId, app.id);

            dataObject.enableOffline();

            // ---- Generate Offline Data ---- //
            if (dataObject.shouldEnableOffline === false) {
                throw Error('Invalid DataObject. DataObject must be flagged to use generated offline data to run online sync')
            }

            const appId = app.id;

            const requestGuid = self.crypto.randomUUID();

            const requestOptions = {
                requestGuid: requestGuid,
                appId: appId,
                dataObjectId: dataObject.id,
                originalViewName: dataObject.viewName,
                viewName: this.mySystemOfflineDataViewName,
                offlineDataType: dataObject.offline.objectStoreIdOverride ?? dataObject.id,
                objectStoreIdOverride: dataObject.offline.objectStoreIdOverride,
                personID: userSession.personId,
                truncateMode: this.truncateMode
                // TODO: add overrides for IndexedDB
            };

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(dataObject, options.memory, requestOptions);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));
                }
            }

            const onMessage = (event: MessageEvent | { data: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress }) => {
                const message: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress = event.data;

                if (message.requestGuid !== requestGuid) {
                    return;
                }

                switch (message.syncType) {
                    case 'OFFLINE-SYNC':
                        options.stepProgress.offlineSyncInProgress = message;
                        break;
                    case 'ONLINE-SYNC':
                        options.stepProgress.onlineSyncInProgress = message;
                        break;
                    case 'TRUNCATE':
                        options.stepProgress.truncateInProgress = message;
                        break;
                }
            };
            navigator.serviceWorker.addEventListener('message', onMessage);

            const response = await fetch(`/nt/api/pwa/truncate/${dataObject.id}`, {
                method: 'POST',
                body: JSON.stringify(requestOptions),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            navigator.serviceWorker.removeEventListener('message', onMessage);

            const responseBodyJson = await response.json();

            const progress: ITruncateProgress = responseBodyJson.progress;

            options.stepProgress.truncateCompletedProgress = progress;

            if (progress.truncateObjectStoreCompletedWithError) {
                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;

                const error = progress.truncateObjectStoreCompletedError;

                if (error) {
                    options.stepProgress.errors.push(new Error(error.serializedErrorObject ?? error.errorCode));

                    let uiFriendlyMessage: UIFriendlyMessage;

                    switch (error.errorCode) {
                        case 'TRUNCATE-UNKOWN-ERROR':
                            uiFriendlyMessage = new UIFriendlyMessage('ERROR', '', '');
                            break;
                    }

                    options.stepProgress.uiFriendlyMessages.push(uiFriendlyMessage);
                }

                return;
            }

            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(dataObject, options.memory, new Array());

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));
                }
            }

            // TODO: Implement
            await new Promise((resolve, _reject) => {
                setTimeout(resolve, 2000);
            });

        } catch (error: any) {
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
        }
    }
}
